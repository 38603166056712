
  input[type=range] {
    appearance: unset;
    height: 45px;
    -webkit-appearance: none;
 

    width: 100%;
    background-color: transparent;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    transition: 0.2s;
    box-shadow: 1px 1px 1px #BDBDBD;
    background: #BDBDBD;
    border-radius: 10px;
    border: 0px solid #BDBDBD;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 0px #ADADAD;
    border: 0px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: #D9D9D9;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -10px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #BDBDBD;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    transition: 0.2s;
    box-shadow: 1px 1px 1px #BDBDBD;
    background: #BDBDBD;
    border-radius: 10px;
    border: 0px solid #BDBDBD;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 0px #ADADAD;
    border: 0px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: #D9D9D9;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    transition: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #BDBDBD;
    border: 0px solid #BDBDBD;
    border-radius: 20px;
    box-shadow: 1px 1px 1px #BDBDBD;
  }
  input[type=range]::-ms-fill-upper {
    background: #BDBDBD;
    border: 0px solid #BDBDBD;
    border-radius: 20px;
    box-shadow: 1px 1px 1px #BDBDBD;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 0px #ADADAD;
    border: 0px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: #D9D9D9;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #BDBDBD;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #BDBDBD;
  }