  .nu-intro{
    position: absolute;
    background-color: var(--dark-blue);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 1;
    transition: 2s;
    overflow: hidden;
  }


  .nu-intro-overlay{
    position: absolute;
    left: -90%;
    top: 30%; 
    border-radius: 100%;
    width: 200%;
    padding-top: 200vw;
    background: linear-gradient(175deg, transparent 10%, var(--light-green) 38%);
    z-index: 1;
    animation: logo;
    animation-duration: 3s;
    animation-timing-function: linear;
  }
  .nu-intro-logo{
    margin-bottom: 2rem;
    z-index: 2;
    animation: logo;
    animation-duration: 1s;
    animation-timing-function: linear;
    transition: 0.5s;
  }

  .login_bg{
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url('../../public/assets/nurse4.png');
    overflow: hidden;
    background-repeat: no-repeat;
    background-color: #e6e7e8;
    background-size: 560px;
    background-position: center 100%;
    transition: background-size 0.5s;
    
  }
  .register_bg{
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url('../../public/assets/nurse2.png');
    overflow: hidden;
  }

  .login_bg_overlay{
    position: relative;
    top: 40vh;
    right: 0px;
    width: 100%;
    padding-top:120vh;
    border-radius: 100%;
    background: linear-gradient(120deg, transparent 30%, rgba(94,235, 214,0.5) 100%);
    /* background-color: red; */
  
  }
  
  .nu_btn{
    width: fit-content;
    max-width: 100%;
    min-width: 115px;
  
    padding:0.35rem 1.15rem 0.35rem 0.75rem;
    background-color: #16B2DE;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    background-color: #E0E0E0;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .nu_btn .nu_btn-overlay {
    display: none;
  }
  .nu_btn:hover{
    background-color: white;
  }
  .nu_btn .nu_btn-text{
    color: #828282;
  }
  .nu_btn:hover > .nu_btn-text{
    color: #828282;
  }
  .nu_btn svg{
    fill: #828282;
  }

  
  .nu_btn--darkgreen{
    background-color: var(--darkgreen);
  }
  .nu_btn--darkgreen:hover{
    background-color: white;
  }
  .nu_btn--darkgreen .nu_btn-overlay{
    display: none;
  }
  .nu_btn--darkgreen .nu_btn-text{
    color: white;
  }
  .nu_btn--darkgreen:hover > .nu_btn-text{
    color: var(--darkgreen);
  }
  .nu_btn--darkgreen svg{
    fill: white;
  }
  .nu_btn--darkgreen:hover svg{
    fill: var(--darkgreen) !important;
  }

  .nu_btn--lightblue{
    background-color: var(--lightblue);
  }
  .nu_btn--lightblue:hover{
    background-color: white;
  }
  .nu_btn--lightblue .nu_btn-overlay{
    display: none;
  }
  .nu_btn--lightblue .nu_btn-text{
    color: white;
  }
  .nu_btn--lightblue:hover > .nu_btn-text{
    color: var(--lightblue);
  }
  .nu_btn--lightblue svg{
    fill: white;
  }
  .nu_btn--lightblue:hover svg{
    fill: var(--lightblue) !important;
  }

 
  .nu_btn--disabled{
    background-color: #CCC;
    cursor:not-allowed;
    pointer-events: none;
  }
  .nu_btn--disabled:hover{
    background-color: #CCC;
  }
  .nu_btn--disabled .nu_btn-overlay{
    display: none;
  }
  .nu_btn--disabled .nu_btn-text{
    color: gray;
  }
  .nu_btn--disabled:hover > .nu_btn-text{
    color: gray;
  }
  .nu_btn--disabled svg{
    fill: gray
  }
  .nu_btn--disabled:hover svg{
    fill: gray !important;
  }
  
  .nu_btn--block{
    background-color: #CCC;
  }
  .nu_btn--block:hover{
    background-color: #ffffff;
  }
  .nu_btn--block .nu_btn-overlay{
    display: none;
  }
  .nu_btn--block .nu_btn-text{
    color: gray;
  }
  .nu_btn--block:hover > .nu_btn-text{
    color: gray;
  }
  .nu_btn--block svg{
    fill: gray
  }
  .nu_btn--block:hover svg{
    fill: gray !important;
  }
  
  
  
  
  .nu_btn--primary{
    background-color: var(--blue);
  }
  .nu_btn--primary:hover{
    background-color: white;
  }
  .nu_btn--primary .nu_btn-overlay{
    display: none;
  }
  .nu_btn--primary .nu_btn-text{
    color: white;
  }
  .nu_btn--primary:hover > .nu_btn-text{
    color: var(--blue);
  }
  .nu_btn--primary svg{
    fill: white;
  }
  .nu_btn--primary:hover svg{
    fill: var(--primary) !important;
  }


  .nu_btn--danger{
    background-color: var(--danger);
  }
  .nu_btn--danger:hover{
    background-color: white;
  }
  .nu_btn--danger .nu_btn-overlay{
    display: none;
  }
  .nu_btn--danger .nu_btn-text{
    color: white;
  }
  .nu_btn--danger:hover > .nu_btn-text{
    color: var(--danger);
  }
  .nu_btn--danger svg{
    fill: white;
    stroke: white;
  }
  .nu_btn--danger:hover svg{
    fill: var(--danger) !important;
    stroke: var(--danger) !important;
  }
  
  
  
  
  .nu_btn--orange{
    background-color: var(--orange);
  }
  .nu_btn--orange:hover{
    background-color: white;
  }
  .nu_btn--orange .nu_btn-overlay{
    display: none;
  }
  .nu_btn--orange .nu_btn-text{
    color: white;
  }
  .nu_btn--orange:hover > .nu_btn-text{
    color: var(--orange);
  }
  .nu_btn--orange svg{
    fill: white;
  }
  .nu_btn--orange:hover svg{
    fill: var(--orange) !important;
  }
  
  
  .nu_btn--gray{
    background-color: gray;
  }
  .nu_btn--gray:hover{
    background-color: white;
  }
  .nu_btn--gray .nu_btn-overlay{
    display: none;
  }
  .nu_btn--gray .nu_btn-text{
    color: white;
  }
  .nu_btn--gray:hover > .nu_btn-text{
    color: gray;
  }
  .nu_btn--gray svg{
    fill: white;
  }
  .nu_btn--gray:hover svg{
    fill: gray !important;
  }

  
  .nu_btn--overlay{
    background-color: var(--blue);
    padding-top: 0.5rem;
    padding-bottom: 0.6rem;
    padding-left: 1.2rem !important;
    padding-right: 1rem !important;
  }
  .nu_btn--overlay:hover{
    background: linear-gradient(120deg, var(--blue) -30%, var(--light-green) 100%);
  }
  .nu_btn--overlay:hover > .nu_btn-overlay{
    display: none;
  }
  .nu_btn--overlay .nu_btn-overlay{
    display: block;
  }
  .nu_btn--overlay .nu_btn-text{
    color: white !important;
  }

  .nu_btn--overlay--svg svg{
    fill: white;
  }
  .nu_btn--overlay--svg:hover svg{
    fill: white !important;
  }
  
  
  .nu_btn-text{
    position: relative;
    z-index: 2;
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    font-family: 'primary';
    font-weight: 600;
  }
  .nu_btn-overlay{
    position: absolute;
    left:calc(-25%);
    top: 15%;
    border-radius: 100%;
    width: 100%;
    padding-top: 100%;
    background: linear-gradient(136deg, var(--blue) 30%, var(--light-green) 70%);
    z-index: 1;
  }
  .nu_input{
    border: 1px solid var(--gray-20);
    border-radius: 6px;
    padding:0.75rem 0.75rem;
    width: 100%;
    font-size: 16px;

  }
  .nu-input{
    border: 1px solid var(--gray-20);
    border-radius: 8px;
    padding:0.5rem 0.5rem;
    width: 100%;
    font-size: 16px;
    background-color: transparent;

  }
  .nu-input--light{
    border: 1px solid var(--gray-20);
    background-color: transparent !important;
    border-radius: 6px;
    padding:0.75rem 0.5rem;
    width: 100%;
    font-size: 16px;
  }
  .nu-input--disabled{

    background-color: var(--gray-10) !important;
  
  }
  .nu-select{
    position: relative;
    

  }
  .bg-red{
    background-color: red !important;
  }
  .nu-select select:focus-visible{
    outline: none;
  
  }
  .nu-select--active select{
    border: 2px solid var(--blue) !important;
    box-shadow: none;
  }
  .nu-select select{
    border: 1px solid var(--gray-20);
    border-radius: 6px;
    padding:.5rem 2.5rem .5rem .5rem;
    outline: hidden;
    
    width: 100%;
    font-size: clamp(14px, 2.5vw, 16px); 
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance: none;
    background-color: transparent;
  }
  .nu-select--sm select{
    border: 1px solid var(--gray-20);
    border-radius: 6px;
    padding:5px 40px 5px 10px;
    outline: hidden;
    font-weight: 500;
    width: 100%;
    font-size: clamp(12px, 2.5vw, 14px); 
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance: none;
    background-color: transparent;
  }
  .nu-select option:first {
    color: #999 !important;
  }
  .nu-select .nu-icon{
    position: absolute;
    pointer-events: none;
    left: calc(100% - 1.4rem);
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .nu-icon{
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent; 
    outline: 2px solid transparent;
  }
  .nu-icon--mid{
    width: 24px;
    height: 24px;
  }
  .nu-icon--big{
    width: 36px;
    height: 36px;
  }
  
  .nu-sidemenu{
    position: relative;
    width: 180px;
    height: calc(100% - 2rem);
    background-color: var(--dark-blue);
    overflow: hidden;
    border-radius: 10px;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
   
  }
  .nu-sidemenu-overlay{
    position: absolute;
    bottom: -90vh;
    left: -600px;
    width: 800px;
    height: 150vh;
    border-radius: 100%;
    background: linear-gradient(120deg, transparent 45%, rgba(94,235, 214,1) 65%);
  }
  .nu-snack{
    position: relative;
    display: flex;
   
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
    background-color: var(--blue);
    overflow: hidden;
  
  
    
  
    
    
    
  }
  .nu-snack-overlay{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 80%;
    height: 180px;
    border-top-right-radius: 100%;
    
    background: linear-gradient(170deg, transparent 20%, rgba(94,235, 214,01) 70%);
    /* background-color: red; */
  }
  .avatar{
    
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    border-radius: 100%;
    background-position: center center;
   /* background-size: 60%; */
   background-color: var(--dark-blue);
  background-repeat: no-repeat;
    background-size: cover;
    outline-offset: 0px;
    
  
  
  }
  .avatar--small{
    width: 48px;
    height: 48px;
  }
  .avatar--mid{
    width: 64px;
    height: 64px;
  }
  .avatar--big{
    width: 84px;
    height: 84px;
  }
  
  .nu-turno-detail{
    /* position: fixed; */
    /* margin-left: 0.5rem; */
    margin-right: -380px;
   padding: 1.25rem;
    width: 380px;
    /* right: 0px; */
    background-color: #f6f6f6;
    height: 100%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    transition: margin 0.2s;
    z-index: 1020;
    overflow-y: auto;
    
  }
  .nu-turno-detail--active{
   margin-right: 0px;
  }
  .nu-turno-detail-image{
    width: 100%;
    aspect-ratio: auto 3/1;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .turno-info-center{
    font-size: 14px;
  }
  .turno-info-center span{
    font-weight: bold;
  }
  .dot-dates{
    display: flex;
  }
  .dot-date{
    margin-left: auto;
    margin-right: auto;
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: var(--gray-30);
  }
  .dot-date--green{
    background-color: var(--darkgreen);
  }
  .dot-date--lightblue{
    background-color: var(--lightblue);
  }
  .dot-date--orange{
    background-color: var(--orange);
  }
  .dot-date--darkblue{
    background-color: var(--dark-blue);
  }
  .tile-block{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.05);
    top: 0px;
    left: 0px;
    width: 100%;
    cursor: not-allowed;
    pointer-events: none;
    position: absolute;
    height: 100%;
    z-index: 2000;
  }


  .dot{
    width: 10px;
    height: 10px;
    margin: 4px;
    border-radius: 10px;
    background-color: var(--gray-30);
  
  }
  .dot--lightblue{
    background-color: var(--lightblue);
  }
  .dot--orange{
    background-color: var(--orange);
  }
  .dot--darkgreen{
    background-color: var(--darkgreen);
  }
  .nu-turno-item{
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
  
    background: linear-gradient(90deg, #E4F0F8 -200%, #FFFFFF 100%);
    /* margin: 0.5rem 1rem; */
    margin-top: 0.5rem;
    border-radius: 10px;
    padding: 0.5rem 0.5rem;
    border: 1px solid #FFFFFF;
    /* border-left: 10px solid var(--darkgreen); */
    border-left: 10px solid var(--gray-10);
    /* height: 110px; */
    overflow: hidden;
    transition: height 0.2s;
  }
  .nu-turno-item--open{
    height: 850px;
  }
  .nu-turno-item--darkgreen{
    border-left: 10px solid var(--darkgreen);
  }
  .nu-turno-item--orange{
    border-left: 10px solid var(--orange);
  }
  .nu-turno-item--danger{
    border-left: 10px solid var(--danger);
  }
  .nu-turno-item--lightblue{
    border-left: 10px solid var(--lightblue);
  }
  .nu-turno-item--gray{
    border-left: 10px solid var(--gray-40);
  }

  .nu-turno-item--darkblue{
    border-left: 10px solid var(--dark-blue);
  }  .nu-tab{
    width: fit-content;
    padding: 0.25rem 1.25rem;
    font-size: 12px;
    /* background-color: rgba(0, 0, 0, 0.05); */
    
    color: #8f8f8f;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
  }
  .nu-tab--active{
    color: var(--dark-blue);
    background-color: var(--skyblue)
  }
  .bar{
    background-color: #D9D9D9;
    height: 8px;
    border-radius: 3px;
  }
  .bar--active{
    background-color: var(--blue);
  }

  .nu-tag{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e6e6e6;
    border-radius: 8px;
    padding: 0.25rem 0.75rem;
    color: #828282;
    width: fit-content;
    font-size: 12px;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    font-weight: 600;
  }
  .nu-tag--active{
    background-color: #828282;
    color: white;
  }
  .nu-tag--green{
    background-color: var(--darkgreen);
    color: white;
  }
  .nu-tag--blue{
    background-color: var(--dark-blue);
    color: white;
  }
  .nu-tag--disabled{
    pointer-events: none !important;
    cursor: not-allowed !important;

  }
  .nu-tag--orange{
    background-color: var(--orange);
    color: white;
  }
  .nu-modal{
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3px);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 2001;

  }
  .nu-modal-body{
    display: flex;
    flex-direction: column;
    position: absolute;
    
    padding: 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background-color: var(--skyblue);
    width: 600px;
    /* height: 100%;
    overflow-y: auto; */
    aspect-ratio: auto 3/2;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    
  }
  .nu-modal-body--fit{
    display: flex;
    flex-direction: column;
    position: absolute;
    
    padding: 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background-color: var(--skyblue);
    width: 760px;
    /* height: 100%;
    overflow-y: auto; */
    aspect-ratio: auto 3/2;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .nu-sumary{
    width: 80px;
    height: 80px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border:10px solid #D9D9D9;
    margin: 0.5rem;
  }
  .nu-sumary--green{
    border-color: var(--darkgreen);
  }
  .nu-sumary--orange{
    border-color: var(--orange);
  }
  .nu-sumary--lightblue{
    border-color: var(--lightblue);
  }
  .nu-loading{
    width: 100vw;
    left: 0px;
    top: 0px;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2002;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nu-loader{
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
 
    background-color: rgba(0, 0, 0, 0.05);
  
    z-index: 2002;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  .notification-holder{

    position: fixed;
    right: 0px;
    padding-top: 0px;
    width: 320px;
    /* pointer-events: none; */
    z-index: 99999;
  }
  .notification{
    /* display: flex;
    flex-direction: column; */
     position: relative;
     right: 0.75rem;
    background-color: white;
    border-radius: 1rem;
    width: 320px;
    border-radius: 4px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    margin: 0.75rem 0rem;
    padding: 0.75rem 0.75rem;
    display: flex;
    
    animation: notification;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
  
  }
  .notification-title{
    font-weight: 900;
    font-size: 15px;

  }
  .notification-message{
    font-size: 14px;
  
  }
  .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin: 10px;
  }
  .icon--big{
    width: 48px;
    height: 48px;
  }
  .btn{
    cursor: pointer;
  }
  .spinner--mid{
    width: 26px !important;
    height: 26px;
    border-width: 2px;
  }


  @property --p{
    syntax: '<number>';
    inherits: true;
    initial-value: 0;
  }
  
  .pie {
    --p:20;
    --b:15px;
    --c:darkred;
    --w:90px;
    
    width:var(--w);
    aspect-ratio:1;
    position:relative;
    display:inline-grid;
    margin:5px;
    place-content:center;
    font-size:18px;
    font-weight:bold;
    font-family:sans-serif;
  }
  .pie:before,
  .pie:after {
    content:"";
    position:absolute;
    border-radius:50%;
  }
  .pie:before {
    inset:0;
    background:
      radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
      conic-gradient(var(--c) calc(var(--p)*1%),#ebebeb 0);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
            mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
  }
 

  .no-round:before {
    background-size:0 0,auto;
  }
  .no-round:after {
    content:none;
  }
 

  .nu-picker{
    border: 1px solid var(--gray-10);
    padding: 5px;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 5px;
  }
  .nu-picker-options{
    border: 1px solid var(--gray-10);
    border-radius: 5px;
    max-height: 250px;
    overflow-y: auto;
    margin-top: 5px;
  }
  .nu-picker-option{
    display: flex;
    align-items: center;
    padding: 2.5px 5px;
  }
  .nu-picker-option--active{
    background-color: rgba(0, 0, 0, 0.03);
  }
  .nu-picker-option:hover{
    background-color: rgba(0, 0, 0, 0.03);
  }
  .nu-badge{
    background-color: var(--blue);
    border-radius: 8px;
  
    width: 32px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 900;
    color: white;
  
  }
  .nudropdow{
    position: relative;
    /* z-index: 2002; */
  }
  .dropdow-items{
    z-index: 2002;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0px;
    right: 100%;
    /* background-color: red; */
  }
  .dropdow-items--bottomcenter{
    right: 10px !important;
    top: 100% !important;
    width: calc(100% - 10px) !important;
  }
  .dropdow-item{
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    padding-right: 2rem;
    /* background-color:  gray; */
  }
  .dropdow-item:hover{
    background-color: rgba(0, 0, 0, 0.05);
  }
  .dropdown-overlay{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 2001;
    background-color: transparent;
    width: 100vw;
    height: 100vh;

  }
  .spinner--small{
    width: 20px;
    height: 20px;
    border-width: 2px;
  }
  





  
  /* .pie {

    width:90px;
    aspect-ratio:1;
    position:relative;
    display:inline-grid;
    margin:5px;
    place-content:center;
    font-size:16px;
    font-weight:bold;
    font-family:sans-serif;
  
  }
  .pie:before,
  .pie:after {
    content:"";
    position:absolute;
    border-radius:50%;
  }
  .pie:before {
    inset:0;
    background:
      radial-gradient(farthest-side,orange 98%,#e7e7e7) top/15px 15px no-repeat,
      conic-gradient(orange calc(80*1%),#e7e7e7 0);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - 15px),#000 calc(100% - 15px));
            mask:radial-gradient(farthest-side,#0000 calc(99% - 15px),#000 calc(100% - 15px));
  }
  .no-round:before {
    background-size:0 0,auto;
  } */

 
  
  @keyframes notification {
    from {
      right: -50px;
      opacity: 0;
    }
  
    to {
      right: 10px;
      opacity: 1;
    }
  }
  
  
  
  

  @media (max-height: 800px) {
    .login_bg{
      background-size: 490px;
    }
  }

  @media (max-height: 700px) {
    .login_bg{
      background-size: 450px;
    }
  }

  @media (max-height: 600px) {
    .login_bg{
      background-size: 360px;
    }
  }

  @keyframes logo {
    from {
      opacity:0
    }
  
    to {
      opacity:1;
    }
  }
  @keyframes intro-overlay {
    from {
        background: linear-gradient(175deg, transparent 30%, var(--light-green) 38%);
    }
  
    to {
        background: linear-gradient(175deg, transparent 10%, var(--light-green) 8%);
    }
  }


  /* MEDIA */

@media (max-width: 1439px){
  .nu-turno-detail{
    position: fixed;
    width: 100%;
    left: 0px;
    top: 0px;
    padding: 0px;
    margin: 0px;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    pointer-events: none;

    
  }
  .nu-turno-content{
    position: absolute;
    height: 100%;
    overflow-y: auto;
    right: -400px;
    width: 400px;
    padding: 1.25rem;
    transition: right 0.2s;
    background-color: #F3F3F3;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
  .nu-turno-detail--active{
    pointer-events: all;
    background-color: rgba(0, 0, 0, 0.2);
   }
  .nu-turno-detail--active .nu-turno-content{
    right: 0px;
  }
}

@media (max-width: 1200px){

  
  .nu-sidemenu{
    width: 80px;
  }
  .nu-sidemenu .logo{
    width: 40%;
  }
  .nu_btn-text{
    font-size: 12px;
  }
  
}
@media (max-width: 992px){

  .nu-modal-body{
    width: 80%;
    aspect-ratio: 4/4 !important;
  }
  .fit-content{
    width: 80% !important;
  }
  

}

@media (max-width: 768px) {
  .nu-sidemenu{
    width: 60px;
    margin-left: 0.25rem;
    margin-top: 0.25rem;
    height: calc(100% - 0.5rem);
  }
}

@media (max-width: 576px){ 
  .nu_btn{
    min-width: unset;
  }
  .nu-modal-body{
    width: 100%;
    aspect-ratio: 2/3 !important;
  }
  .fit-content{
    width: 100% !important;
  }

  .nu-turno-detail{
    width: 100%;
    margin: 0px !important;
    
  }
  .nu-turno-detail--active .nu-turno-content{
    right: 0px;
    width: 100%;
  }

  .nu-sidemenu{
    display: flex;
    width: calc(100% - 0.5rem);
    position: absolute;
    z-index: 10001;
    left: -100%;
    transition: left 0.1s;
    /* width: 60px;
    margin-left: 0.25rem;
    margin-top: 0.25rem;
    height: calc(100% - 0.5rem); */
  }
  .nu-sidemenu-show{
    left: 0px !important;
  }
  .nu-sidemenu .f-14{
    font-size: 14px;
  }
  .nu-turno-item{
  
  }
  .nu-sidemenu .logo{
    width: 60px;

  }

}
