.bv-calendar{
    background-color: transparent;
    width: 320px;
    border-radius: 8px;
  }
  .react-calendar__tile{
    position: relative;
  }
  .bv-calendar-day{
    /* width: 20px;
    height: 0px; */
    padding-bottom: 6px;
    font-size: 14px;
    font-weight: 600;
    color: #777;
    border: none;
    background-color: transparent;
    
    border-radius: 4px;
    border: 2px solid transparent;
  }
  .bv-calendar-day:hover{
    /* border: 2px solid var(--dark-blue); */
    background-color: rgba(0, 0, 0, 0.03);
    
  }
  .react-calendar__navigation{
    display: flex;
    padding-bottom: 15px;

  }
  .react-calendar .react-calendar__navigation button{
    background-color: transparent;
    border: none;
    flex-grow: 1
  }
  .react-calendar__navigation__arrow{
    color: var(--dark-blue);
    font-size: 20px;
  }
  .bv-calendar-v1 .react-calendar__tile--active{
    border: 2px solid var(--dark-blue);
    position: relative;
  }
  .react-calendar__navigation__label__labelText{
    font-weight: 600;
    text-transform: capitalize;
  }
  .react-calendar__tile--now{
    /* border: 2px solid var(--dark-blue); */
  }
  .react-calendar__tile--now{
    position: relative;
  }
  /* .react-calendar__tile--active::before,  */
  .react-calendar__tile--now::after{
    /* content: '•';
    color: var(--orange);
    font-size: 16px;
    position: absolute;
    bottom: -60%;
    left: calc(60% + 1px);
    transform: translate(-50% ,-50%); */
  }
  .react-calendar__tile--now::before{
    /* content: '•';
    color: var(--dark-blue);
    font-size: 16px;
    position: absolute;
    bottom: -60%;
    left: calc(35% + 1px);
    transform: translate(-50% ,-50%); */
  }

  
  
  .react-calendar__month-view__weekdays{
    display: flex;


    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
    text-decoration: none;
    color: #777;
  
  }
  .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday{
  
   
  }
  .react-calendar__month-view__weekdays abbr[title]{
    text-decoration: none;
  }


  .calendar-place{
    /* border: 1px solid red; */
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    pointer-events: none;

  }
  .place-selected{
    position: absolute;
    width: 45px;
    height: 30px;
    /* background-color: rgba(0, 0, 0, 0.1); */
    left: 183px;
    top: 85px;
    border: 2px solid var(--dark-blue);
    border-radius: 4px;
  }
  .bv-calendar abbr{
    pointer-events: none;
  }