

.container-fluid, .row, .row > *{
    padding: 0px;
    margin: 0px;
  }
  .container{
    padding: 0px;
  }
@media (max-width: 768px){
    .container, .container-md, .container-sm {
        max-width: 100%;
    }
}
@media (max-width: 576px){
    .container, .container-sm {
        max-width: 100%;
    }
}