:root{

  --black-alpha-10: rgba(0, 0, 0, 0.1);
  --black-alpha-20: rgba(0, 0, 0, 0.2);
  --black-alpha-30: rgba(0, 0, 0, 0.3);
  --black-alpha-40: rgba(0, 0, 0, 0.4);
  --black-alpha-50: rgba(0, 0, 0, 0.5);
  --black-alpha-60: rgba(0, 0, 0, 0.6);
  --black-alpha-70: rgba(0, 0, 0, 0.7);
  --black-alpha-80: rgba(0, 0, 0, 0.8);
  --gray-00: #F5F6F7;
  --gray-10: #EBEDF0;
  --gray-20: #DADDE1;
  --gray-30: #BEC3C9;
  --gray-40: #8D949E;
  --gray-70: #606770;
  --gray-80: #444950;
  --gray-90: #303338;
  --gray-100: #1C1E21;
  
  --primary: #30B7E7;
  --primary-alpha:rgba(22,178,222);
  --primary-alpha-80:rgba(48,183,231, 0.8);
  --primary-alpha-30:rgba(48,183,231, 0.3);
  --secondary: #FBC029;
  --secondary-alpha-80:rgba(251,192,41, 0.8);
  --green: #37D4AD;
  --darkgreen: #00943A;
  --red:#FC1E03;
  --lightblue: #546FFF;
  --lightpurple: #8E92BC;
  --skyblue: #F3F8FB;


  --orange: #FF812A;
  --yellow: #FFB815;
  --light-green: #5EEBD6;
  --blue: #16B2DE;
  --dark-blue: #0D78BF;
  --danger: #DF2222;
}
@font-face {
  font-family: 'primary';
  src: url("../public/assets/primary.ttf");
}
a {
  all: unset;
}

.container-fluid, .row, .row > *{
  padding: 0px;
  margin: 0px;
}
.container{
  padding: 0px;
}
body,html{
  margin: 0px;
  padding: 0px;
  font-family: 'primary';
  /* min-width: 1440px; */
}
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll; 
}
body::-webkit-scrollbar {
  display: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* UTILS */
.sticky-top-header{
    position: -webkit-sticky;
    position: sticky;
    top: calc(112px);
    z-index: 1020;
}
.hide-scroll{
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll; 
  overflow-x: scroll; 
}
.hide-scroll::-webkit-scrollbar{
  display: none;
}
.prevent-select {
  -webkit-user-select: none; 
  -ms-user-select: none;
  user-select: none;
}
.border{
  border: 1px solid red !important;
}
.border-gray{
  border: 1px solid var(--gray-20)
}
.f-10{
  font-size: 10px;
}
.f-12{
  font-size: 12px;
}
.f-14{
  font-size: clamp(12px, 2.5vw, 14px); 
}
.f-16{
  /* font-size: 16px; */
  font-size: clamp(14px, 2.5vw, 16px); 
}
.f-18{
  /* font-size: 18px; */
  font-size: clamp(14px, 2.5vw, 18px); 
}
.f-20{
  /* font-size: 20px; */
  font-size: clamp(16px, 2.5vw, 20px); 
}
.f-22{
  /* font-size: 22px; */
  font-size: clamp(18px, 2.5vw, 22px); 
}
.f-24{
  font-size: clamp(18px, 2.5vw, 24px); 
}
.f-26{
  font-size: 24px;
}
.f-28{
  font-size: 28px;
}
.f-30{
  font-size: 30px;
}
.f-32{

  font-size: clamp(22px, 2.5vw, 32px); 
}
.f-34{
  font-size: 34px;
}
.r-100{
  border-radius: 100%;
}
.r-5{
  border-radius: 5px
}
.r-8{
  border-radius: 8px;
}
.r-10{
  border-radius: 10px;
}
.rt-10{

  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.fit-content{
  width: fit-content;
}
.prevent-select{
  user-select: none;
}
.text-darkblue{
  color: var(--dark-blue);
}
.text-lightpurple{
  color: var(--lightpurple);
}
.text-lightgreen{
  color: var(--light-green);
}
.text-darkgreen{
  color: var(--darkgreen);
}
.text-orange{
  color: var(--orange);
}
.text-lightblue{
  color: var(--lightblue);
}
.text-gray{
  color: #828282;
}
.text-dark{
  color: #333333;
}
.text-darkgeen{
  color: var(--darkgreen);
}
.text-red{
  color: var(--danger);
}

.bold{
  font-weight: 600;
}
.bolder{
  font-weight: bold;
}
.cursor-pointer{
  cursor: pointer;
  user-select: none;
}
.event-none{
  pointer-events: none !important;
}
.envets-none{
  pointer-events: none;
  opacity: 0.8;
}
.bubble-arrow{
  position: absolute;
    right: -10px;
    bottom: 5px;
      width: 0;
      height: 0;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-bottom: 50px solid var(--dark-blue);
}
.bubble-arrow2{
  position: absolute;
    right: -7px;
    bottom: 5px;
      width: 0;
      height: 0;
      border-left: 12.5px solid transparent;
      border-right: 12.5px solid transparent;
      border-bottom: 25px solid var(--dark-blue);
}
.bg-cover{
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.bg-overlay{
  background: linear-gradient(160deg, rgba(22,178,222) 25%, rgba(94,235, 214,1) 100%);
}
.bg-darkblue{
  background-color: var(--dark-blue);
}
.bg-orange{
  background-color: var(--orange);
}
.bg-gray{
  background-color: #F3F3F3;
}
.bg-gray-00{
  background-color: var(--gray-00);
}
.bg-skyblue{
  background-color: var(--skyblue);
}
.bg-skyblue--overlay:hover{
  background-color: rgba(226, 243, 253, 0.9) !important
}

.b-b{
  border-bottom: 1px solid #e7e7e7;
}
.b-t{
  border-top: 1px solid #e7e7e7;
}
.absolute-center{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.line-0{
  line-height: 1 !important;
}
.line-1{
  line-height: 1.2;
}
.ellipsis{
  overflow: hidden; 
  text-overflow: ellipsis;
  white-space: nowrap;
}
.b-overlay-white{
  background: linear-gradient(white, white) padding-box,
  linear-gradient(to right, var(--primary), var(--light-green)) border-box;
  border-radius: 100%;
  border: 3px solid transparent; 
}
.b-overlay-black{
  background: linear-gradient(black, black) padding-box,
  linear-gradient(to right, var(--primary), var(--light-green)) border-box;
  border-radius: 100%;
  border: 3px solid transparent; 
}
.z-1{
  z-index: 1;
}
.z-2{
  z-index: 1;
}
.transition{
  transition: all 0.7s;
  
}
.no-wrap{
  white-space: nowrap;
}
.max-100{
  max-width: 100% !important;
  white-space: nowrap !important;
}
.myclass table {
  table-layout: auto !important;
}

.myclass th, .myclass td, .myclass thead th, .myclass tbody td, .myclass tfoot td, .myclass tfoot th {
  width: auto !important;
}

.overlay{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1021;
  background-color: rgba(0, 0, 0, 0.2);
}
.letter{
  height: 95%;
  max-width: 680px;
  /* width: 100%; */
  overflow: auto;
  background: white;
}
.ul-disc{
  list-style:disc;
}
.ul-letter{
  list-style: alpha;
}
.text-justify{
  text-align:justify;
}
.tycos_table td{
  font-size: 14px;
padding: 10px;
border: 1px solid #CCC;
  text-align: center;
}

.step{
  cursor: pointer;
  user-select: none;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  padding: 10px;
  background: white;
  color:#16B2DE;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #16B2DE;

}
.step--active{
  background: #16B2DE;
  color: #FFF;
}
.step-line{
  flex: 1;
  width: 100px;
  height: 2px;
  background: #16B2DE

}
.step-line--active{
  background:#16B2DE;
}

/* COMPONENTS */

/* Reenviar Link*/
.link-disabled {
  color: grey;
  cursor: not-allowed;
  text-decoration: none;
}

.link-enabled {
  color: blue; 
  cursor: pointer;
  text-decoration: underline;
}
/* Tooltip */
.tooltip-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-content {
  visibility: hidden;
  background-color: rgba(145, 135, 135, 0.081);
  color: #000000;
  text-align: left;
  border-radius: 6px;
  padding: 8px;
  border: 1px solid #888; 
  max-width: 500px; 
  width: 370px;
  word-wrap: break-word;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 100%;
  white-space: normal;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;

  /* Optional arrow */
  &:after {
    content: "";
    position: absolute;
    right: 100%;
    top: 100%;
    margin-top: -12px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
}

.tooltip-target:hover + .tooltip-content,
.tooltip-content:hover {
  visibility: visible;
  opacity: 1;
}


/* hubspotForm */
#hubspotForm {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  box-sizing: border-box;
  width: 70%;
  margin: auto;
}
/* MEDIA */

@media (max-width: 1400px){
}
@media (max-width: 1200px){

 
  
}
@media (max-width: 992px){
.letter{
  width: 100%;
}

  

}
@media (max-width: 768px) {
  .r-sm-10{
    border-radius: 10px;
  }
  .bg-sm-gray{
    background-color: #F3F3F3;
  }

  .sticky-top-header{
    position: -webkit-sticky;
    position: sticky;
    top: calc(88px);
    z-index: 1020;
  }
}
@media (max-width: 576px){ 
  .sticky-top-header{
    position: -webkit-sticky;
    position: sticky;
    top: calc(87px);
    z-index: 1020;
  }

}
